<template>
  <div class="formalContain">
    <div class="pititle">
      <div class="zhidingsearch">
        <el-form>
          <div class="litop">
            <el-row>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-form-item label="标准名称">
                    <el-input v-model="proName" placeholder="请输入您的标准名称" clearable
                      @keyup.enter.native="search()"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple-light">
                  <el-form-item label="主编单位">
                    <el-input v-model="firstUnit" placeholder="请输入您的主编单位" clearable
                      @keyup.enter.native="search()"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-form-item label="标准类型">
                    <el-select v-model="biaozhunType" placeholder="标准类型" clearable @keyup.enter.native="search()">
                      <el-option label="标准" :value="1"></el-option>
                      <el-option label="标准设计" :value="2"></el-option>
                      <!-- <el-option label="推荐行技术规程" :value="3"></el-option> -->
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple-light">
                  <el-form-item label="制定类型">
                    <el-select v-model="zhidingType" placeholder="制定类型" clearable @keyup.enter.native="search()">
                      <el-option label="制定" :value="1"></el-option>
                      <el-option label="修订" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="4">
                <!-- <div class="grid-content bg-purple">
                  <el-button type="primary" @click="search">查询</el-button>
                  <el-button type="info" @click="qingkong">清空</el-button>
              </div> -->
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-form-item label="选择年份">
                    <el-date-picker v-model="date" type="year" placeholder="选择年" value-format="yyyy" clearable
                      @keyup.enter.native="search()">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-form-item label="项目状态">
                    <el-select v-model="status" placeholder="项目状态" clearable multiple @keyup.enter.native="search()">
                      <el-option label="待立项" :value="2"></el-option>
                      <el-option label="不立项" :value="5"></el-option>
                      <el-option label="待提交初稿" :value="6"></el-option>
                      <el-option label="待初稿审查" :value="7"></el-option>
                      <el-option label="待提交征求意见稿" :value="9"></el-option>
                      <el-option label="待征求意见" :value="10"></el-option>
                      <el-option label="待提交送审稿及材料" :value="11"></el-option>
                      <el-option label="待送审" :value="12"></el-option>
                      <el-option label="待提交报批稿" :value="13"></el-option>
                      <el-option label="待报批" :value="14"></el-option>
                      <el-option label="已报批" :value="15"></el-option>
                      <el-option label="留档" :value="16"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple" style="color：#000000">
                  <span v-if="status == 1">待提交</span>
                  <span v-else-if="status == 2">待立项</span>
                  <span v-else-if="status == 3">待修改中</span>
                  <span v-else-if="status == 4">待立项</span>
                  <span v-else-if="status == 5">不立项</span>
                  <span v-else-if="status == 6">待提交初稿</span>
                  <span v-else-if="status == 7">待初稿审查</span>
                  <span v-else-if="status == 8">待完善审查材料</span>
                  <span v-else-if="status == 9">待提交征求意见稿</span>
                  <span v-else-if="status == 10">待征求意见</span>
                  <span v-else-if="status == 11">待提交送审稿及材料</span>
                  <span v-else-if="status == 12">待送审</span>
                  <span v-else-if="status == 13">待提交报批稿</span>
                  <span v-else-if="status == 14">待报批</span>
                  <span v-else-if="status == 15">报批</span>
                  <span v-else-if="status == 16">留档</span>
                </div>
              </el-col>

              <el-col :span="5">
                <div class="grid-content bg-purple">
                </div>
              </el-col>
              <el-col :span="3">
                <div class="grid-content bg-purple">
                  <el-form-item>
                    <span>数量为：{{ totalCount }}</span>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-form-item label="标签检索">
                    <el-select v-model="labelkey" placeholder="标签检索" clearable multiple @focus="labelDataList()"
                      @keyup.enter.native="search()" @change="markChange">
                      <el-option v-for="(item, index) in labelList" :key="index" :value="item.name"></el-option>
                      <!-- <el-option label="标签3" :value="5"></el-option> -->
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple" style="display: flex; flex-direction: row-reverse">
                  <el-button type="info" @click="qingkong" style="margin-left: 10px">清空</el-button>
                  <el-button type="primary" @click="search">查询</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <div class="piconetn">
        <div class="program">
          <div class="check_pro"><el-checkbox v-model="isChecked" @change="showEnd()">不显示终止项目</el-checkbox></div>
          <div style="margin-bottom: 20px; float: right">
            <el-button type="primary" @click="moveOver">变更</el-button>
            <!-- <el-button type="danger" @click="suspend">延期</el-button> -->
            <el-button type="success" @click="lixiang">立项</el-button>
            <!-- <el-button type="danger" @click="bulixiang">不立项</el-button> -->
            <el-button type="primary" @click="picihao">申请经费</el-button>
            <el-button type="primary" @click="exportExcel">申报信息导出</el-button>

            <el-button type="primary" @click="tolink">标准数据导出</el-button>
          </div>
        </div>
        <!-- 表格 -->
        <div class="pitable">
          <el-table :data="tableData" :row-class-name="tabRowClassName" style="width: 100%"
            @selection-change="selected_change" :header-cell-style="{ background: '#3082E2', color: '#FFF' }"
            :row-key="rowId">
            <el-table-column reserve-selection type="selection" width="55">
            </el-table-column>
            <el-table-column label="序号" type="index"> </el-table-column>
            <el-table-column label="立项编号" prop="number" align="center">
              <template slot-scope="{ row }">
                <el-button type="text" @click="modifyProject(row)">{{ row.number }}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="标准名称" prop="proName" align="center">
              <template slot-scope="{ row }">
                <router-link :to="gotoStep(row)" class="linkto">
                  <span>{{ row.proName }}</span>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column label="标准类型" align="center" width="130px">
              <template slot-scope="{ row }">
                <span v-if="row.biaozhunType == 1">标准</span>
                <span v-if="row.biaozhunType == 2">标准设计</span>
                <span v-if="row.biaozhunType == 3">区域标准</span>
              </template>
            </el-table-column>
            <el-table-column label="制定类型" align="center" width="130px">
              <template slot-scope="{ row }">
                <span v-if="row.zhidingType == 1">制定</span>
                <span v-if="row.zhidingType == 2">修订</span>
              </template>
            </el-table-column>
            <el-table-column label="主编单位" prop="firstUnit" align="center">
            </el-table-column>
            <el-table-column label="项目负责人" prop="firstProHandler" align="center" width="130px">
            </el-table-column>
            <!-- <el-table-column label="标识星级" align="center">
              <template slot-scope="{ row }">
                <i
                  v-for="item in row.after_stars"
                  :key="item"
                  class="el-icon-star-on"
                ></i>
              </template>
            </el-table-column>
            <el-table-column label="标识阶段" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.stage == 1">能效评测</span>
                <span v-if="row.stage == 2">实测评估</span>
              </template>
            </el-table-column> -->
            <el-table-column label="申请日期" prop="created_at" align="center" width="130px">
              <template slot-scope="{ row }">
                <span>{{ row.created_at | formatDate("yyyy-MM-dd") }}</span>
              </template>
            </el-table-column>
            <el-table-column label="项目状态" align="center" width="150px">
              <template slot-scope="{ row }">
                <span>{{ row.status_name }}</span>
                <!-- <span v-if="row.status == 1" style="color: #e6a23c"
                  >待提交</span
                > -->
                <!-- <span v-if="row.status == 2" style="color: #409eff">待立项</span>
                <span v-else-if="row.status == 3" style="color: #e6a23c">待修改中</span>
                <span v-else-if="row.status == 4" style="color: #f56c6c">待立项</span>
                <span v-else-if="row.status == 5">立项不通过</span>
                <span v-else-if="row.status == 6" style="color: #e6a23c">待提交初稿</span>
                <span v-else-if="row.status == 7" style="color: #f56c6c">待初稿审查</span>
                <span v-else-if="row.status == 8" style="color: #e6a23c">待完善审查材料</span>
                <span v-else-if="row.status == 9" style="color: #e6a23c">待提交征求意见稿</span>
                <span v-else-if="row.status == 10" style="color: #f56c6c">待征求意见</span>
                <span v-else-if="row.status == 11" style="color: #e6a23c">待提交送审稿及材料</span>
                <span v-else-if="row.status == 12" style="color: #f56c6c">待送审</span>
                <span v-else-if="row.status == 13" style="color: #e6a23c">待提交报批稿</span>
                <span v-else-if="row.status == 14" style="color: #f56c6c">待报批</span>
                <span v-else-if="row.status == 15" style="color: #67c23a">已报批</span>
                <span v-else-if="row.status == 16" style="color: #909399">留档</span>
                <span v-else-if="row.status == 17" style="color: #909399">暂停</span>
                <span v-else-if="row.status == 18" style="color: #909399">终止</span> -->
              </template>
            </el-table-column>
            <el-table-column label="经费" prop="bokuan" align="center" width="130px">
            </el-table-column>
            <el-table-column label="标签" prop="batch" align="center" width="200px">
              <template slot-scope="{ row }">
                <div class="label_list">
                  <div v-for="(item, index) in row.label" :key="index" class="btns">
                    <el-tag type="info" v-if="item.label != null" size="mini">{{ item.label }} <span
                        @click="delLabel(row, item.label)"><i class="el-icon-close"></i></span></el-tag>
                  </div>
                  <span class="lf_label" @click="addLabel(row.id)"><el-button type="text"
                      icon="el-icon-circle-plus-outline"></el-button></span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="260px">
              <template slot-scope="{ row }">
                <el-button type="danger" @click="del(row.id)" size="mini" icon="el-icon-delete"></el-button>
                <!-- <el-button type="primary" @click="addLabel(row.id)" size="mini">新增标签</el-button> -->
                <!-- <el-button type="success" @click="modifyProject(row)" size="mini">修改立项</el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pageTotal">
          <div class="block">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="pici_show" title="输入经费" width="30%">
      <el-radio-group v-model="fundlabel" class="pass_text">
        <el-radio label="0">重点</el-radio>
        <el-radio label="1">普通标签</el-radio>
      </el-radio-group>
      <el-input v-model="batch" placeholder="请输入经费" v-if="fundlabel === '0'"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pici_show = false">取 消</el-button>
        <el-button type="primary" @click="add_pici">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="batch_show" title="立项" width="30%">
      <el-radio-group v-model="isPass" class="pass_text">
        <el-radio label="0">立项</el-radio>
        <el-radio label="1">不立项</el-radio>
      </el-radio-group>
      <el-input v-model="number" placeholder="请输入立项编号" v-if="isPass === '0'"></el-input>
      <el-input type="textarea" placeholder="请填写不立项原因" v-model="lixiang_content" v-else></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batch_show = false">取 消</el-button>
        <el-button type="primary" @click="add_batch">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="move_show" title="变更" width="40%">
      <el-form>
        <el-form-item label="状态">
          <el-radio-group v-model="status_over">
            <el-radio label="延期" name="17"></el-radio>
            <el-radio label="终止" name="18"></el-radio>
            <el-radio label="变更" name="19"></el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="status_over === '延期' || status_over === '终止'">
          <el-form-item label="备注">
            <el-input type="textarea" v-model="textnote"></el-input>
          </el-form-item>
        </div>
        <div v-if="status_over === '变更'">
          <el-form-item label="标准名称">
            <el-input v-model="newProName"></el-input>
          </el-form-item>
          <el-form-item label="主编单位">
            <el-input v-model="newfirstUnit"></el-input>
          </el-form-item>
          <el-form-item label="项目负责人">
            <el-input v-model="firstProHandler"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="contact_us"></el-input>
          </el-form-item>
          <el-form-item label="更名原因">
            <el-input type="textarea" v-model="content"></el-input>
          </el-form-item>
          <div class="record_text">变更记录</div>
          <div>
            <div class="record_text">原名称: {{ projectIds }}</div>
            <div class="record_text">更改后名称: {{ newProjectIds }}</div>
            <div class="record_text">更名原因: {{ contentIds }}</div>
          </div>
        </div>
        <el-form-item label="资料上传">
            <el-upload :file-list="fileList" action="" :http-request="uploadFile" class="upload-demo">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="move_show = false">取 消</el-button>
        <el-button type="primary" @click="moveSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog :visible.sync="stop_show" title="暂停或终止" width="30%">
      <el-form>
        <el-form-item label="状态">
          <el-radio-group v-model="status_over">
            <el-radio label="暂停" name="17"></el-radio>
            <el-radio label="终止" name="18"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="textnote"></el-input>
        </el-form-item>
        <el-form-item label="资料上传">
          <el-upload :file-list="fileList" action="" :http-request="uploadFile" class="upload-demo">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="stop_show = false">取 消</el-button>
        <el-button type="primary" @click="stop_sure">确 定</el-button>
      </span>
    </el-dialog> -->
    <el-dialog :visible.sync="label_show" title="新增标签" width="30%">
      <el-form>
        <el-form-item label="标签名称" class="label_input">
          <el-select v-model="labelSubitem" placeholder="标签检索" clearable multiple @focus="labelDataList()"
            @change="markItemChange">
            <el-option v-for="(item, index) in labelList" :key="index" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="label_show = false">取 消</el-button>
        <el-button type="primary" @click="label_sure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="project_show" title="修改立项" width="30%">
      <el-form>
        <el-form-item label="立项编号">
          <el-input v-model="projectApproval" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="project_show = false">取 消</el-button>
        <el-button type="primary" @click="project_sure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="exportShow" title="标准数据导出" width="30%">
      <el-form>
        <el-form-item label="导出方式">
          <el-radio v-model="exportType" label="1">筛选导出</el-radio>
          <el-radio v-model="exportType" label="2">全部导出</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShow = false">取 消</el-button>
        <el-button type="primary" @click="export_sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
//getProjectList
import {
  getBiaozhunList,
  addBiaozhun,
  delformalPend,
  projectNameEdit,
  projectNameLog,
  fileWordUpload,
  standardStatusEdit,
  projectAddLabel,
  labelSelectData,
  projectApprovalEdit,
  projectLabelDel,
  chooseExcel,
  screenExcel,
  fundsSave
  // exportCheck
} from "../../../../api/project";
import axios from "axios";
import request from "@/utils/request";
export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      //选中的表格数据
      selectedProjects: [],
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      status: "",
      token: "",
      firstUnit: "",
      proName: "",
      zhidingType: "",
      date: "",
      number: "",
      batch: "",
      biaozhunType: "",
      batch_show: false,
      isPass: '0',
      pici_show: false,
      move_show: false,
      stop_show: false,
      proNameChange: "",
      firstUnitChange: "",
      labelkey: "",
      originName: "",
      firstProHandler: "",
      content: "",
      contact_us: '', //联系方式
      status_over: "延期",
      textnote: "",
      projectRecords: [],
      projectIds: "",
      newProjectIds: "",
      contentIds: "",
      newProName: "",
      newfirstUnit: "",
      fileList: [],
      filename: "",
      fileUrl: "",
      label_show: false,
      formData: {
        markList: [""],
      },
      rowID: "",
      labelList: [],
      markData: [],
      markIds: [],
      labelSubitem: "",
      project_show: false,
      projectApproval: "",
      exportShow: false,
      exportType: "1",
      baseURL: "",
      fundlabel: "0",
      lixiang_content: "",
      isChecked: true
    };
  },
  mounted() {
    this.getToken();
    this.baseURL = request.defaults.baseURL;
    //this.getbiaozhunList();
    this.getList()
  },
  methods: {
    picihao() {
      if (this.selectedProjects.length < 1) {
        this.$message({
          showClose: true,
          message: "请选择项目",
          type: "warning",
        });
        return;
      } else {
        console.log("232323");
        this.pici_show = true;
        this.batch = ''
      }
    },
    add_pici() {
      for (let i = 0; i <= this.selectedProjects.length - 1; i++) {
        console.log(this.selectedProjects[i], '打印元素')
        let id = this.selectedProjects[i].id
        this.selectedProjects[i].bokuan = this.fundlabel === '0' ? this.batch : 0;
        this.selectedProjects[i].is_keynote = this.fundlabel === '0' ? 1 : 2;
        this.selectedProjects[i].id = id;
        this.selectedProjects[i].token = this.token;
        // console.log('this.selectedProjects[i]',this.selectedProjects[i])
        fundsSave(this.selectedProjects[i]).then((rec) => {
          console.log(rec);
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          this.pici_show = false;
          this.getList()
        });
      }
    },
    tolink() {
      //导出表单
      if (this.selectedProjects.length < 1) {
        this.$message({
          showClose: true,
          message: "至少选择一个项目导出",
          type: "warning",
        });
        return;
      }
      this.exportShow = true
    },
    //确认导出
    export_sure() {
      let params = null
      if (this.exportType === '1') {
        // 筛选导出
        params = {
          token: this.token,
          proName: this.proName,
          firstUnit: this.firstUnit,
          zhidingType: this.zhidingType,
          biaozhunType: this.biaozhunType,
          status: this.status == '' ? '' : this.status.join(","),
          date: this.date,
          batch: this.batch,
        }
      } else if (this.exportType === '2') {
        // 勾选导出
        let ids = this.selectedProjects.map(val => val.id);
        params = {
          token: this.token,
          ids: ids.toString()
        }
      }
      this.exportFiles(this.exportType, params)
    },
    //导出文件下载
    exportFiles(exportType, sendparams) {
      axios.get(exportType === '1' ? this.baseURL + 'api/standard/exports' : this.baseURL + 'api/standard/export', {
        params: sendparams,
        responseType: "blob", //设置类型
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'csv'
        },
      }).then(res => {
        console.log(res, '打印返回')
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const downloadUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = '标准数据.xlsx'
        link.click()
      });
    },
    rowId(row) {
      return row.id;
    },
    getToken() {
      this.token = this.$store.state.token; //获取cookie字符串
      console.log(this.token);
      this.labelDataList();
      // this.getList();
    },
    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "warning-row";
      }
    },
    markChange(val) {
      this.markData = val
    },
    markItemChange(val) {
      let tagList = val
      var filterdata = this.labelList.filter(item => tagList.some(x => x === item.name))
      // console.log('打印过滤的数据', filterdata)
      this.markIds = filterdata.map(val => val.id)
    },
    //查询标签数据
    labelDataList() {
      labelSelectData({ token: this.token }).then((rec) => {
        console.log(rec, '打印标签的数据');
        this.labelList = rec.data.filter(item => item.name != null);
      });
    },
    //获得表格数据
    getList() {
      console.log('打印7903020')
      let data = {
        limit: this.limit,
        page: this.page,
        change_status: this.isChecked ? 0 : 1,
        token: this.token,
        proName: this.proName,
        firstUnit: this.firstUnit,
        zhidingType: this.zhidingType,
        biaozhunType: this.biaozhunType,
        status: this.status == '' ? '' : this.status.join(","),
        date: this.date,
        batch: this.batch,
        label: this.markData
      };
      getBiaozhunList(data).then((rec) => {
        this.tableData = rec.data;
        this.limit = rec.limit;
        this.page = rec.page;
        this.totalCount = rec.totalCount;
        this.totalPage = rec.totalPage;
        console.log(rec);
      });
    },
    //搜索
    search() {
      this.page = 1;
      this.getList();
    },
    //清空
    qingkong() {
      this.firstUnit = ""
      this.proName = ""
      this.zhidingType = ""
      this.biaozhunType = ""
      this.markData = []
      this.labelkey = ''
      this.status = ''
      this.getList();
    },
    // 分页
    handleSizeChange(page) {
      // this.limit = page;
      this.getList();
      // console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      // debugger
      // console.log(page)
      //console.log(`当前页: ${page}`)
      this.page = page;
      this.getList();
    },
    //表格多选
    selected_change(val) {
      this.selectedProjects = val;
      console.log(this.selectedProjects);
    },
    gotoStep(row) {
      let url = "/formal/proDetailForZhongxin?id=" + row.id;
      return url;
    },
    //不显示终止的项目
    showEnd() {
      //  console.log('触发', this.isChecked)
      this.getList();
    },
    exportExcel() {
      //导出表单
      let url = "https://api.gbservice.cn/api/standard/export?";
      let idarr = this.selectedProjects.map((item) => {
        return item.id;
      });
      let ids = idarr.toString();
      url += "token=" + this.$store.state.token;
      url += "&ids=" + ids;
      window.open(url, "blank");
      console.log(url);
      // getExcel({ids:ids,token:this.token}).then( rec =>{
      //   console.log(rec)
      // })
    },
    //查询变更的日志
    queryProjectData() {
      projectNameLog({ id: this.selectedProjects[0].id, token: this.$store.state.token }).then((rec) => {
        // console.log(rec, '查询结果');
        this.projectRecords = rec.data
        //将多个值分割一起展示
        this.projectRecords.map((item, i) => {
          if (i == this.projectRecords.length - 1) {
            this.projectIds += item.old_data.firstProHandler;
            this.newProjectIds += item.new_data.firstProHandler;
            this.contentIds += item.content
          }
          else {
            this.projectIds += item.old_data.firstProHandler + ",";
            this.newProjectIds += item.new_data.firstProHandler + ",";
            this.contentIds += item.content + ",";
          }
        })
        // console.log(this.projectIds, '打印值多个')
      });
    },
    //变更
    moveOver() {
      // console.log('打印数据', this.selectedProjects)
      this.projectIds = ""
      this.newProjectIds = ""
      this.contentIds = ""
      this.textnote = ""
      if (this.selectedProjects.length < 1) {
        this.$message({
          showClose: true,
          message: "请选择要变更的项目",
          type: "warning",
        });
        return;
      } else if (this.selectedProjects.length > 1) {
        this.$message({
          showClose: true,
          message: "只能选择一个变更",
          type: "warning",
        });
        return;
      } else {
        this.move_show = true;
        this.newProName = this.selectedProjects[0].proName
        this.newfirstUnit = this.selectedProjects[0].firstUnit
        this.firstProHandler = this.selectedProjects[0].firstProHandler
      }
      //查询变更的日志
      this.queryProjectData()
    },
    //暂停
    suspend() {
      //  this.stop_show = true
      if (this.selectedProjects.length < 1) {
        this.$message({
          showClose: true,
          message: "至少选择一个项目",
          type: "warning",
        });
        return;
      } else if (this.selectedProjects.length > 1) {
        this.$message({
          showClose: true,
          message: "只能选择一个项目",
          type: "warning",
        });
        return;
      } else {
        this.stop_show = true;
      }
    },
    //变更确认
    moveSure() {
      //加一个手机号码的校验
      let status_over = null
      if (this.status_over === '延期') {
        status_over = 2
      } else if (this.status_over === '终止') {
        status_over = 1
      } else {
        status_over = 0
        const reg = /^1[3456789]\d{9}$/
        if (!reg.test(this.contact_us)) {
          // 手机号码不符合规则
          this.$message({
            type: "warning",
            message: "请输入有效的联系方式!",
          });
          return false
        }
      }

      //入参加一个联系方式 todo
      let params = {
        id: this.selectedProjects[0].id,
        token: this.$store.state.token,
        proName: this.newProName,
        firstUnit: this.newfirstUnit,
        firstProHandler: this.firstProHandler,
        content: this.content,
        contact_us:this.contact_us,
        url: this.fileUrl,
        change_status: status_over
      }
      projectNameEdit(params).then((rec) => {
        console.log(rec);
        if (rec.code == 200) {
          this.$message({
            showClose: true,
            message: rec.message,
            type: "success",
          });
          this.move_show = false
          this.getList(); //成功之后查询列表
        }
      });
    },
    //确认暂停
    stop_sure() {
      let params = {
        id: this.selectedProjects[0].id,
        token: this.$store.state.token,
        status: this.status_over === '暂停' ? '17' : '18',
        status_url: this.fileUrl,
        status_notes: this.textnote,
      }
      standardStatusEdit(params).then((rec) => {
        console.log(rec);
        if (rec.code == 200) {
          this.$message({
            showClose: true,
            message: rec.message,
            type: "success",
          });
          this.stop_show = false
          this.getList()
        }
      });
    },
    //新增标签确认
    label_sure() {
      // if (this.formData.markList[0] == '') {
      //   this.$message({
      //     message: "标签不能为空",
      //     type: "warning",
      //   });
      //   return false
      // } else {
      let params = {
        id: this.rowID,
        token: this.$store.state.token,
        label: this.markIds
      }
      projectAddLabel(params).then((rec) => {
        console.log(rec);
        if (rec.code == 200) {
          this.$message({
            showClose: true,
            message: rec.message,
            type: "success",
          });
          // this.formData.markList = [""]
          this.label_show = false
          this.getList()
        }
      });
      // }
    },
    //上传文件
    uploadFile(file) {
      const formData = new FormData();
      this.filename = file.file.name
      // 向 formData 对象中添加文件
      formData.append("file", file.file);
      fileWordUpload(formData)
        .then((res) => {
          console.log('打印上传', res)
          // this.fileName = res.data.name
          this.fileUrl = res.data.url
          if (res.code == 200) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    lixiang() {
      if (this.selectedProjects.length < 1) {
        this.$message({
          showClose: true,
          message: "请选择项目",
          type: "warning",
        });
        return;
      }
      let sp = this.selectedProjects.find((item) => item.status != 2); //!=4存在其他状态的项目
      if (sp != undefined) {
        console.log(sp);
        this.$message({
          showClose: true,
          message: "请选择正确的项目立项",
          type: "warning",
        });
        return;
      } else {
        console.log("232323");
        this.batch_show = true;
      }
    },
    add_batch() {
      for (let i = 0; i <= this.selectedProjects.length - 1; i++) {
        if (this.isPass === '0') {
          //立项
          this.selectedProjects[i].status = 6;
        } else {
          //不立项
          this.selectedProjects[i].status = 5;
        }

        this.selectedProjects[i].number = this.number;
        this.selectedProjects[i].token = this.token;
        this.selectedProjects[i].lixiang_content = this.lixiang_content;
        // console.log('this.selectedProjects[i]',this.selectedProjects[i])
        addBiaozhun(this.selectedProjects[i]).then((rec) => {
          console.log(rec);
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          this.batch_show = false;
          this.getList();
        });
      }
    },
    bulixiang() {
      if (this.selectedProjects.length < 1) {
        this.$message({
          showClose: true,
          message: "请选择项目",
          type: "warning",
        });
        return;
      }
      // let sp = this.selectedProjects.find((item) => item.status != 4); //!=4存在其他状态的项目
      // if (sp != undefined) {
      //   console.log(sp);
      //   this.$message({
      //     showClose: true,
      //     message: "请选择正确的项目操作",
      //     type: "warning",
      //   });
      //   return;
      // } else {
      for (let i = 0; i <= this.selectedProjects.length - 1; i++) {
        this.selectedProjects[i].status = 5; //修改状态
        this.selectedProjects[i].token = this.token;
        //console.log('this.selectedProjects[i]',this.selectedProjects[i].status)
        addBiaozhun(this.selectedProjects[i]).then((rec) => {
          console.log(rec);
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
        });
      }
      // }
    },
    del(rowID) {
      this.$confirm("此操作将删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delformalPend({ id: rowID, token: this.token }).then((rec) => {
          console.log(rec);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          setTimeout(() => {
            this.$router.go(0);
          }, 500);
        });
      });
    },
    addform() {
      if (this.formData.markList.length < 6) {
        this.formData.markList.push('')
      } else if (this.formData.markList.length > 6) {
        this.$message({
          type: "warning",
          message: "最多可新增6条!",
        });
        return false
      }
    },
    delform(index) {
      if (this.formData.markList.length < 2) {
        this.$message({
          type: "warning",
          message: "至少保留一个!",
        });
        return false
      }
      this.formData.markList.splice(index, 1)
    },
    addLabel(rowID) {
      this.label_show = true
      this.rowID = rowID
      this.labelSubitem = []
    },
    //修改立项
    modifyProject(row) {
      console.log(row)
      this.project_show = true
      this.rowID = row.id
      this.projectApproval = row.number
    },
    //提交立项
    project_sure() {
      if (this.projectApproval == '') {
        this.$message({
          type: "warning",
          message: "立项编号不能为空!",
        });
        return false
      }
      this.$confirm("是否确认修改, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = {
          token: this.token,
          id: this.rowID,
          number: this.projectApproval
        }
        projectApprovalEdit(data).then((rec) => {
          // console.log('打印返回', rec)
          if (rec.code == '200') {
            this.$message({
              type: "success",
              message: rec.message,
            });
            this.getList()
            this.project_show = false
          }
        });
      });
    },
    //删除标签
    delLabel(row, label) {
      let data = {
        id: row.id,
        label: label,
        token: this.token,
      }
      this.$confirm("是否删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        projectLabelDel(data).then((rec) => {
          console.log('打印返回', rec)
          if (rec.code == '200') {
            this.$message({
              type: "success",
              message: rec.message,
            });
            this.getList()
          }
        });
      });
    }
  },
};
</script>
<style scoped>
/deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
}

/deep/.el-button--primary:hover {
  background-color: #409eff;
  border-color: #409eff;
}

/deep/.el-input__suffix {
  right: 43px;
  transition: all 0.3s;
  border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
  background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
  min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}

/deep/.el-form-item__label {
  font-family: Adobe Heiti Std;
  color: #2d2d2d;
}

/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-size: 16px;
}

/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}

.pitable {
  /*height: 60vh;*/
}

.litop {
  margin: 20px 24px;
}

.litop span {
  /* color: #3086fb; */
  /* margin-left: 10px; */
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.prbtn {
  display: flex;
  flex-direction: row-reverse;
}

.linkto {
  text-decoration: none;
  color: #3082e2;
  cursor: pointer;
}

.linkto>span:hover {
  color: #84b5ec;
}

/* 新改 */
.zhidingsearch {
  background-color: #ffffff;
  padding: 1px 15px;
  margin: 20px 0 10px 0;
}

.piconetn {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 9px 1px #eeeeee;
}

/* .stand_name {
  margin-bottom: 20px;
} */
.record_text {
  color: #2d2d2d;
  font-size: 14px;
  line-height: 24px;
}

.addBtn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

/deep/ .label_input .el-input {
  /* width: 70%; */
  margin-right: 11px;
}

.inconent .label_list {
  /* display: flex;
  flex-wrap: wrap;
  align-content: flex-start; */
}

.inconent .label_list .btns {
  margin-right: 8px;
  padding-bottom: 5px;
}

.label_list .lf_label {
  padding-right: 12px;
}

.formalContain .pass_text {
  margin-bottom: 23px;
}

.piconetn .program {
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
}

.piconetn .check_pro {
  width: 200px;
  margin: 9px 0;
}
</style>
